// src/components/WeatherSection.js
import React from 'react';
import { Sunrise, Sunset } from 'lucide-react';
import HourlyForecast from './HourlyForecast';
import DailyForecast from './DailyForecast';
import { formatSunTime } from '../utils/dateUtils';

const WeatherSection = ({ weatherData }) => {
  if (!weatherData) return null;

  const sunriseTime = formatSunTime(weatherData.current.sunrise);
  const sunsetTime = formatSunTime(weatherData.current.sunset);

  return (
    <div className="h-full flex flex-col">
      <div className="flex justify-end space-x-4 mb-2 text-sm">
        <div className="flex items-center">
          <Sunrise className="w-4 h-4 text-yellow-400 mr-1" />
          <span>{sunriseTime}</span>
        </div>
        <div className="flex items-center">
          <Sunset className="w-4 h-4 text-orange-400 mr-1" />
          <span>{sunsetTime}</span>
        </div>
      </div>
      <div className="flex-grow overflow-y-auto">
        <HourlyForecast hourlyData={weatherData.hourly} />
        <DailyForecast dailyData={weatherData.daily} />
      </div>
    </div>
  );
};

export default WeatherSection;