// src/components/VelibSection.js
import React, { useMemo } from 'react';
import StationInfo from './StationInfo';

const VelibSection = ({ stationInfo, stationStatus }) => {
  const stationIds = ['253357936', '82499609', '128890772', '1057091571'];

  const stations = useMemo(() => {
    if (!stationInfo || !stationStatus) return [];

    return stationIds.map(id => {
      const info = stationInfo.data.stations.find(s => s.station_id.toString() === id);
      const status = stationStatus.data.stations.find(s => s.station_id.toString() === id);

      if (!info || !status) return null;

      return {
        id: id,
        name: info.name,
        code: info.stationCode,
        mechanical: status.num_bikes_available_types.find(t => t.mechanical)?.mechanical || 0,
        electric: status.num_bikes_available_types.find(t => t.ebike)?.ebike || 0,
        docks: status.num_docks_available,
        isInstalled: status.is_installed,
        isReturning: status.is_returning,
        isRenting: status.is_renting
      };
    }).filter(Boolean);
  }, [stationInfo, stationStatus]);

  return (
    <div className="w-[35%] mr-2">
      <div className="container bg-gray-800 p-2 rounded-lg h-full">
        <h2 className="text-xl font-bold mb-2">Velib' Metropole</h2>
        <div className="space-y-2">
          {stations.map(station => (
            station.isInstalled && station.isReturning && station.isRenting ? (
              <StationInfo 
                key={station.id}
                name={station.name} 
                mechanical={station.mechanical} 
                electric={station.electric}
                docks={station.docks}
              />
            ) : (
              <div key={station.id} className="bg-yellow-600 p-1 rounded text-sm">
                <p className="font-bold truncate">{station.name}</p>
                <p>Maintenance</p>
              </div>
            )
          ))}
        </div>
      </div>
    </div>
  );
};

export default VelibSection;