import React from 'react';
import { Sun, Cloud, CloudRain, CloudSnow, CloudLightning, Wind } from 'lucide-react';

const getWeatherIcon = (iconCode) => {
    switch (iconCode) {
        case '01d':
        case '01n':
            return <Sun className="w-6 h-6 text-yellow-400" />;
        case '02d':
        case '02n':
        case '03d':
        case '03n':
        case '04d':
        case '04n':
            return <Cloud className="w-6 h-6 text-gray-400" />;
        case '09d':
        case '09n':
        case '10d':
        case '10n':
            return <CloudRain className="w-6 h-6 text-blue-400" />;
        case '11d':
        case '11n':
            return <CloudLightning className="w-6 h-6 text-yellow-600" />;
        case '13d':
        case '13n':
            return <CloudSnow className="w-6 h-6 text-white" />;
        case '50d':
        case '50n':
            return <Wind className="w-6 h-6 text-gray-300" />;
        default:
            return <Cloud className="w-6 h-6 text-gray-400" />;
    }
};

const DailyForecast = ({ dailyData }) => {
  return (
      <div className="flex justify-between mt-1">
          {dailyData.slice(0, 5).map((day, index) => (
              <div key={index} className="flex flex-col items-center w-1/5">
                  <p className="text-sm mb-1">{index === 0 ? 'Today' : new Date(day.dt * 1000).toLocaleDateString('fr-FR', { weekday: 'short' })}</p>
                  <div className="mb-1">{getWeatherIcon(day.weather[0].icon)}</div>
                  <div className="text-sm">
                      <span className="text-red-400 font-bold">{Math.round(day.temp.max)}°</span>
                      <br></br>
                      <span className="text-blue-400">{Math.round(day.temp.min)}°</span>
                  </div>
              </div>
          ))}
      </div>
  );
};

export default DailyForecast;