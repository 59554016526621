import React from 'react';
import { Sun, Cloud, CloudRain, CloudSnow, CloudLightning, Wind } from 'lucide-react';

const getWeatherIcon = (iconCode) => {
    switch (iconCode) {
        case '01d':
        case '01n':
            return <Sun className="w-8 h-8 text-yellow-400" />;
        case '02d':
        case '02n':
        case '03d':
        case '03n':
        case '04d':
        case '04n':
            return <Cloud className="w-8 h-8 text-gray-400" />;
        case '09d':
        case '09n':
        case '10d':
        case '10n':
            return <CloudRain className="w-8 h-8 text-blue-400" />;
        case '11d':
        case '11n':
            return <CloudLightning className="w-8 h-8 text-yellow-600" />;
        case '13d':
        case '13n':
            return <CloudSnow className="w-8 h-8 text-white" />;
        case '50d':
        case '50n':
            return <Wind className="w-8 h-8 text-gray-300" />;
        default:
            return <Cloud className="w-8 h-8 text-gray-400" />;
    }
};

const HourlyForecast = ({ hourlyData }) => {
  return (
      <div className="flex justify-between mb-1">
          {hourlyData.slice(0, 4).map((hour, index) => (
              <div 
                  key={index} 
                  className={`flex flex-col items-center w-1/4 ${index === 0 ? 'weather-now' : ''}`}
              >
                  <p className="text-sm mb-1">{index === 0 ? 'Now' : new Date(hour.dt * 1000).getHours() + 'h'}</p>
                  <div className="mb-1">{getWeatherIcon(hour.weather[0].icon)}</div>
                  <p className="text-lg font-bold mb-1">{Math.round(hour.temp)}°C</p>
                  <p className="text-xs">{Math.round(hour.pop * 100)}%</p>
              </div>
          ))}
      </div>
  );
};

export default HourlyForecast;