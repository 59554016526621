// src/ParisTransportDashboard.js
import React, { useState, useEffect } from 'react';
import VelibSection from './components/VelibSection';
import PublicTransportSection from './components/PublicTransportSection';
import WeatherSection from './components/WeatherSection';
import AlertSection from './components/AlertSection';
import DateTimeSection from './components/DateTimeSection';
import { fetchWeatherData, fetchMetroAlerts, fetchMetroData, fetchVelibStationInfo, fetchVelibStationStatus, fetchTramData } from './utils/api';
import FullscreenButton from './components/FullscreenButton';

const ParisTransportDashboard = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [weatherData, setWeatherData] = useState(null);
  const [metroAlerts, setMetroAlerts] = useState([]);
  const [metroDataBalard, setMetroDataBalard] = useState(null);
  const [metroDataCreteil, setMetroDataCreteil] = useState(null);
  const [tramData, setTramData] = useState(null);
  const [velibStationInfo, setVelibStationInfo] = useState(null);
  const [velibStationStatus, setVelibStationStatus] = useState(null);

  useEffect(() => {
    const updateData = async () => {
      try {
        // Fetch weather data
        const newWeatherData = await fetchWeatherData();
        if (newWeatherData) {
          setWeatherData(newWeatherData);
        }

        // Fetch Velib station info
        const newVelibStationInfo = await fetchVelibStationInfo();
        if (newVelibStationInfo) {
          setVelibStationInfo(newVelibStationInfo);
        }
        // Fetch Velib station status
        const newVelibStationStatus = await fetchVelibStationStatus();
        if (newVelibStationStatus) {
          setVelibStationStatus(newVelibStationStatus);
        }

        // Fetch metro alerts
        const newMetroAlerts = await fetchMetroAlerts();
        if (newMetroAlerts) {
          setMetroAlerts(newMetroAlerts);
        }

        // Fetch metro data for Balard
        const newMetroDataBalard = await fetchMetroData('Balard');
        if (newMetroDataBalard) {
          setMetroDataBalard(newMetroDataBalard);
        }

        // Fetch metro data for Creteil
        const newMetroDataCreteil = await fetchMetroData('Créteil');
        if (newMetroDataCreteil) {
          setMetroDataCreteil(newMetroDataCreteil);
        }

        // Fetch tram data
        const newTramData = await fetchTramData();
        if (newTramData) {
          setTramData(newTramData);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Initial data fetch
    updateData();

    // Set up intervals for data updates
    const dataUpdateInterval = setInterval(updateData, 60000); // Update data every minute
    const timeUpdateInterval = setInterval(() => setCurrentTime(new Date()), 1000); // Update time every second

    // Clean up intervals on component unmount
    return () => {
      clearInterval(dataUpdateInterval);
      clearInterval(timeUpdateInterval);
    };
  }, []);

  return (
    <div className="relative flex flex-col h-screen w-screen animate-bg-gradient-gray bg-black text-white p-2 overflow-hidden">
      <FullscreenButton />
      <div className="flex h-[95%]">
        <VelibSection 
          stationInfo={velibStationInfo} 
          stationStatus={velibStationStatus} 
        />
        <PublicTransportSection 
          metroDataBalard={metroDataBalard} 
          metroDataCreteil={metroDataCreteil}
          tramData={tramData}
        />
        <div className="w-[35%] ml-2 flex flex-col h-full">
          <DateTimeSection currentTime={currentTime} />
          <div className="flex-grow overflow-hidden">
            <WeatherSection weatherData={weatherData} />
          </div>
        </div>
      </div>
      <AlertSection metroAlerts={metroAlerts} />
    </div>
  );
};

export default ParisTransportDashboard;