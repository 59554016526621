export const fetchWeatherData = async () => {
  try {
    const response = await fetch('https://home-dashboard.manetli.com/api/weather.json', {
      cache: 'no-store' // Ceci désactive le cache
    });
    return await response.json();
  } catch (error) {
    console.error("Erreur lors de la récupération des données météo:", error);
    return null;
  }
};

export const fetchMetroAlerts = async () => {
  try {
    const response = await fetch('https://home-dashboard.manetli.com/api/prim.line_reports.IDFM.C01378.json');
    const data = await response.json();
    return data.disruptions;
  } catch (error) {
    console.error("Erreur lors de la récupération des alertes métro:", error);
    return [];
  }
};

export const fetchMetroData = async (direction) => {
  const id = direction === 'Balard' ? '463211' : '22187';
  try {
    const response = await fetch(`https://home-dashboard.manetli.com/api/prim.stop-monitoring.IDFM.C01378.${id}.json`);
    return await response.json();
  } catch (error) {
    console.error(`Erreur lors de la récupération des données métro pour ${direction}:`, error);
    return null;
  }
};

export const fetchTramData = async () => {
  // Implémentation à ajouter
  // Pour l'instant, retournons des données factices
  return Promise.resolve({
    // Structure similaire à celle du métro
    Siri: {
      ServiceDelivery: {
        StopMonitoringDelivery: [{
          MonitoredStopVisit: [
            // Données factices du tram
          ]
        }]
      }
    }
  });
};

export const fetchVelibStationInfo = async () => {
  try {
    const response = await fetch('https://home-dashboard.manetli.com/api/prim.velib_station_information.json', {
      cache: 'no-store'
    });
    return await response.json();
  } catch (error) {
    console.error("Erreur lors de la récupération des informations des stations Vélib:", error);
    return null;
  }
};

export const fetchVelibStationStatus = async () => {
  try {
    const response = await fetch('https://home-dashboard.manetli.com/api/prim.velib_station_status.json', {
      cache: 'no-store'
    });
    return await response.json();
  } catch (error) {
    console.error("Erreur lors de la récupération du statut des stations Vélib:", error);
    return null;
  }
};