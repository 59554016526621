// src/components/StationInfo.js
import React from 'react';
import bikeElec from '../assets/images/Bike_elec@3x.png';
import bikeMeca from '../assets/images/Bike_meca@3x.png';

const StationInfo = ({ name, mechanical, electric, docks }) => {
  const iconWidth = 24; // Réduction supplémentaire de la taille des icônes
  const iconHeight = (iconWidth / 186) * 122;

  return (
    <div className="bg-gray-700 p-2 rounded-lg">
      <p className="text-sm font-semibold mb-1 truncate">{name}</p>
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <img 
            src={bikeMeca} 
            alt="Vélo mécanique" 
            style={{ width: `${iconWidth}px`, height: `${iconHeight}px` }}
            className="mr-1"
          />
          <span className="text-base font-bold">{mechanical}</span>
        </div>
        <div className="flex items-center">
          <img 
            src={bikeElec} 
            alt="Vélo électrique" 
            style={{ width: `${iconWidth}px`, height: `${iconHeight}px` }}
            className="mr-1"
          />
          <span className="text-base font-bold">{electric}</span>
        </div>
        <div className="flex items-center">
          <div 
            className="bg-blue-500 rounded-full flex items-center justify-center mr-1"
            style={{ width: `${iconWidth}px`, height: `${iconWidth}px` }}
          >
            <span className="text-white font-bold text-xs">P</span>
          </div>
          <span className="text-base font-bold">{docks}</span>
        </div>
      </div>
    </div>
  );
};

export default StationInfo;