// src/components/AlertSection.js
import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useSwipeable } from 'react-swipeable';

const AlertSection = ({ metroAlerts }) => {
  const [currentAlertIndex, setCurrentAlertIndex] = useState(0);

  useEffect(() => {
    if (metroAlerts.length > 1) {
      const interval = setInterval(() => {
        setCurrentAlertIndex((prevIndex) => (prevIndex + 1) % metroAlerts.length);
      }, 10000); // Change alert every 10 seconds
      return () => clearInterval(interval);
    }
  }, [metroAlerts]);

  const getAlertMessage = (alert) => {
    if (alert && alert.messages && alert.messages.length > 0) {
      return alert.messages.find(msg => msg.channel.name === "titre")?.text || "Alerte sans détails";
    }
    return "Alerte sans détails";
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => setCurrentAlertIndex((prevIndex) => (prevIndex + 1) % metroAlerts.length),
    onSwipedRight: () => setCurrentAlertIndex((prevIndex) => (prevIndex - 1 + metroAlerts.length) % metroAlerts.length),
  });

  return (
    <div {...handlers} className="h-[15%] mt-1 bg-blue-900 p-2 rounded text-center flex items-center justify-center relative overflow-hidden">
      <AnimatePresence mode="wait">
        {metroAlerts.length > 0 ? (
          <motion.div
            key={currentAlertIndex}
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
            transition={{ duration: 0.5 }}
          >
            <p>{getAlertMessage(metroAlerts[currentAlertIndex])}</p>
          </motion.div>
        ) : (
          <p>Aucune alerte en cours</p>
        )}
      </AnimatePresence>
      {metroAlerts.length > 0 && (
        <div className="absolute bottom-1 right-2 text-xs">
          {currentAlertIndex + 1}/{metroAlerts.length}
        </div>
      )}
    </div>
  );
};

export default AlertSection;