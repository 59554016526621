// src/components/PublicTransportSection.js
import React from 'react';
import TransportInfo from './TransportInfo';

const PublicTransportSection = ({ metroDataBalard, metroDataCreteil, tramData }) => {
  return (
    <div className="w-[30%] mx-2">
      <div className="container animate-gradient-gray bg-gray-800 p-3 rounded-lg h-full overflow-y-auto">
        <h2 className="text-3xl mb-4 font-bold">Transports</h2>
        <div className="space-y-4">
          <TransportInfo from="Lourmel" to="Créteil" transportData={metroDataCreteil} type="metro" />
          <TransportInfo from="Lourmel" to="Balard" transportData={metroDataBalard} type="metro" />
          {tramData && <TransportInfo from="T2" to="Thai Union" transportData={tramData} type="tram" />}
        </div>
      </div>
    </div>
  );
};

export default PublicTransportSection;