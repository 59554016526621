// src/components/DateTimeSection.js
import React from 'react';
import { formatTime, formatDate, formatDay, formatMonthYear } from '../utils/dateUtils';

const DateTimeSection = ({ currentTime }) => {
  return (
    <div className="flex justify-end items-start">
      <div className="container container-date p-1 rounded-lg text-center mr-4">
        <p className="text-5xl">{formatDate(currentTime)}</p>
        <p className="text-2xl mt-2">{formatDay(currentTime)}</p>
      </div>
      <div className="pr-2">
        <p className="text-7xl leading-none font-sf-rails font-normal" style={{ width: '4ch', fontWeight: 400 }}>
          {formatTime(currentTime)}
        </p>
        <p className="text-xl mt-2">{formatMonthYear(currentTime)}</p>
      </div>
    </div>
  );
};

export default DateTimeSection;