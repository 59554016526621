// src/components/TransportInfo.js
import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { calculateTimeRemaining } from '../utils/timeUtils';

const AnimatedTime = ({ time }) => (
  <AnimatePresence mode="wait">
    <motion.span
      key={time}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.2 }}
      className="text-yellow-400 font-bold"
      style={{ fontSize: '40px', lineHeight: 1 }}
    >
      {time.toString().padStart(2, '0')}
    </motion.span>
  </AnimatePresence>
);

const TransportInfo = ({ from, to, transportData, type }) => {
  const [timeRemaining, setTimeRemaining] = useState([]);

  useEffect(() => {
    const updateTimes = () => {
      if (transportData && transportData.Siri.ServiceDelivery.StopMonitoringDelivery[0].MonitoredStopVisit) {
        const times = transportData.Siri.ServiceDelivery.StopMonitoringDelivery[0].MonitoredStopVisit
          .map(visit => calculateTimeRemaining(visit.MonitoredVehicleJourney.MonitoredCall.ExpectedDepartureTime))
          .filter(time => time > 0)
          .slice(0, 3);
        setTimeRemaining(times);
      } else {
        setTimeRemaining([]);
      }
    };

    updateTimes();
    const timer = setInterval(updateTimes, 1000);
    return () => clearInterval(timer);
  }, [transportData]);

  const getTransportIcon = () => {
    switch(type) {
      case 'metro':
        return <div className="w-3 h-3 bg-green-400 rounded-full mr-2" />;
      case 'tram':
        return <div className="w-3 h-3 bg-blue-400 rounded-full mr-2" />;
      default:
        return null;
    }
  };

  return (
    <div className="mb-4">
      <div className="flex items-center mb-2">
        {getTransportIcon()}
        <p className="text-xl">{from} &gt; {to}</p>
      </div>
      <div className="flex items-center space-x-4">
        {timeRemaining.length > 0 ? (
          timeRemaining.map((time, index) => (
            <div key={index} className="flex items-baseline">
              <AnimatedTime time={time} />
              <span className="text-yellow-400 font-bold" style={{ fontSize: '20px', marginLeft: '-2px' }}>MIN</span>
            </div>
          ))
        ) : (
          <span className="text-red-400">Aucun {type} prévu</span>
        )}
      </div>
    </div>
  );
};

export default TransportInfo;